import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero/hero"
import RightCta from "../components/cta/right-bordered/cta"
import LeftCta from "../components/cta/left-bordered/cta"


import desktopHero from "../images/cardiovascular-risk/1.0_1440x540_Desktop_Hero_1X.png"
import mobileHero from "../images/cardiovascular-risk/1.0_543x800_Mobile_Hero_2X.png"
import glassHeart from "../images/cardiovascular-risk/1.0_550x402_Glass_Heart_Desktop_1X.png"
import glassHeartMobile from "../images/cardiovascular-risk/glassHeartMobile.png"
import doctor from "../images/cardiovascular-risk/1.0_750x402_Doctor_Patient_Desktop_1X.png"
import doctorMobile from "../images/cardiovascular-risk/Doctor_Patient_mobile_new.png"
import gradientOne from "../images/cardiovascular-risk/gradientOneNew.png"
import gradientTwo from "../images/cardiovascular-risk/gradientTwo.svg"
import heartTips from    "../images/cardiovascular-risk/heart-tips@2x.png"
import golferMobile from    "../images/cardiovascular-risk/1.0_543x300_Golfer_Mobile_2X.png"
import ctaBackground from "../images/cardiovascular-risk/ctaBackground.svg"
// import mobiletest from "../images/mobile-item.png"
// import mobileherotest from "../images/mobile-hero.png"

// import Savings from "../components/savings/savings"


export default () => (
  <Layout>
    <SEO
      title="What's Your Cardiovascular Risk?"
      keywords = "cardiovascular risk factors, cardiovascular disease risk factors"
      description="If you have high cholesterol, diabetes, or high blood pressure, and high triglycerides (≥ 150 mg/dL), doing all you can to protect your heart is critical. Learn how VASCEPA® (icosapent ethyl) may help. Please see Indication and Important Safety Information."
      />


    <Hero
      desktopHeroBackground={desktopHero}
      mobileHeroBackground={mobileHero}
      alt="Patient and a Doctor"
      headline={[<span>It’s time to take cardiovascular (CV) risk <span className="no-wrap">to heart</span></span>]}
      content={["If you have risk factors like heart disease (including previous heart attack or stroke) and raised triglycerides, ", 
                "doing all you can to protect your heart is critical. Prescription VASCEPA is the first and only FDA-approved medication clinically proven to reduce the risk of a CV event by 25%* when added to a statin.",<sup className="no-gradient sup-header">&dagger;</sup>,
                <br/>,
                <p style={{display:"inline-block",marginTop:"10px", paddingLeft: "5px", textIndent: "-5px", position:"relative", left:"-5px", marginBottom: "0px"}} className="footnote-font">*In adults on maximally tolerated statins with TG ≥150 mg/dL and established CVD or diabetes and ≥2 CVD risk factors.</p>,
                <p style={{display:"inline-block",marginTop:"0px", paddingLeft: "5px", textIndent: "-5px", position:"relative", left:"-5px"}} className="footnote-font"><sup>&dagger;</sup>In a clinical study, patients treated with VASCEPA and a statin had fewer CV events (17.2%) compared to those who took placebo (22%).</p>
              ]}
      additionalClassName = "height640 height640-mobile"
      />

    <RightCta
      colOne="5"
      colTwo="7"
      whiteborder="whiteborder"
      backgroundGradient="#098ab4"
      image={glassHeart}
      alt="A clear VASCEPA® (icosapent ethyl) heart"
      mobileImage={glassHeartMobile}
      background={gradientOne}
      headline="Separate fact from fiction when it comes to protecting your heart from a CV event"
      cta={["Take the CV Risk Quiz ",<span aria-hidden="true">&gt;</span>]}
      url="/cardiovascular-risk/quiz"
      ctaSubClass="cta-no-padding"
      contentHeadline="h3-separate"
      mobileImageClass="img-autowidth"
      />

    <LeftCta
        ctaBackground = {ctaBackground}
        colOne="5"
        colTwo="7"
        image={doctor}
        mobileImage={doctorMobile}
        alt="Man discussing his heart protection plan with VASCEPA® (icosapent ethyl) with his doctor"
        backgroundColor = "#E7E8EA"
        whiteborder="transparentborderRight"
        headline="Planning a chat with your doctor? See what VASCEPA may do for you."
        cta={["VASCEPA benefits ", <span aria-hidden="true">&gt;</span>]}
        headlineColor = "#1D355E"
        ctaColor = "#1D355E"
        imgSM= "right"
        url="/benefits/"
        contentSubClass="left"
        ctaSubClass="left"
        mobileImageClass="img-autowidth cardio-doctor"

        />

    <RightCta
      colOne="5"
      colTwo="7"
      whiteborder="whiteborder"
      image={heartTips}
      alt="Checkbook with heart"
      mobileImage={heartTips}
      background={gradientTwo}
      backgroundGradient="#cabfd9"
      headline="Add more heart-healthy tips to your daily routine"
      cta={[<span style={{whiteSpace : "nowrap"}}>Helpful tips and guidance <span aria-hidden="true">&gt;</span></span>]}
      headlineColor = "#1D355E"
      ctaColor = "#1D355E"
      url="/cardiovascular-risk/healthy-heart-tips"
      ctaSubClass="cta-no-padding-a"
      />

  </Layout>
)
